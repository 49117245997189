<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <ics-search-inner :search-form="searchForm" more-btn="高级搜索" :fold-search.sync="showFoldSearch" @submit-search="search" @clear-search="clearSearch">
        <template>
          <el-col :span="6">
            <el-form-item label="黑名单编号">
              <el-input v-model="searchForm.blackCode" placeholder="请输入要搜索的黑名单编号" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="融资企业名称">
              <el-input v-model="searchForm.customerName" placeholder="请输入要搜索的融资企业名称" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="统一社会信用代码">
              <el-input v-model="searchForm.customerSocialNo" placeholder="请输入要搜索的统一社会信用代码" />
            </el-form-item>
          </el-col>
          <el-col />
          <el-col v-show="showFoldSearch" :span="6">
            <el-form-item label="创建人">
              <el-input v-model="searchForm.createdBy" placeholder="请输入要搜索的创建人" />
            </el-form-item>
          </el-col>
          <el-col v-show="showFoldSearch" :span="12">
            <el-form-item label="创建时间">
              <ics-search-date :date.sync="createdArr" />
            </el-form-item>
          </el-col>
        </template>
      </ics-search-inner>
    </div>
    <div class="partition-table">
      <ics-table-inner v-loading="loading.list" :column-option="columnOption" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :hide-export-btn="true" @changePageNum="changePageNum">
        <template slot="table-columns-before">
          <el-table-column fixed="left" label="序号" type="index" :index="indexMethod" />
        </template>
      </ics-table-inner>
    </div>
  </el-main>
</template>

<script>
import { basePageListMixin } from '@/assets/js/mixins'
import utils from '@/assets/js/utils'

export default {
  components: {},
  mixins: [basePageListMixin],
  data () {
    return {
      showFoldSearch: false,
      baseSearchForm: {
        blackCode: '',
        customerName: '',
        customerSocialNo: '',
        createdBy: '',
        createdBeginAt: '',
        createdEndAt: ''
      },
      dialog: {
        visible: false,
        title: '移入黑名单',
        audit: {
          visible: false,
          title: '审批白名单'
        }
      },
      businessId: '',
      operationType: '',
      appForm: {
        id: '',
        customerName: '',
        customerSocialNo: '',
        reason: ''
      },
      customerList: [],
      rules: {
        id: [
          { required: true, message: '请选择移入黑名单的企业', trigger: 'change' }
        ]
      },
      columnOption: [
        { label: '黑名单编号', prop: 'blackCode', formatter: this.utils.isEffective, canSet: false, fixed: 'left', minWidth: 100 },
        { label: '所属核心企业', prop: 'coreName', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '融资企业名称', prop: 'customerName', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '统一社会信用代码', prop: 'customerSocialNo', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '创建时间', prop: 'createdAt', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '创建人', prop: 'createdBy', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '业务状态', prop: 'state', formatter: (row, col, cell) => this.utils.statusFormat(cell, 'whiteBusinessState'), minWidth: 140 },
      ]
    }
  },
  computed: {
    createdArr: utils.computedDate('createdBeginAt', 'createdEndAt')
  },
  methods: {
    getList () {
      this.loading.list = true
      this.api.risk.black.pageList(this.utils.filterTrimValue(this.searchForm)).then(result => {
        this.tableList = result.data.data
      }).finally(() => {
        this.loading.list = false
      })
    },
    move () {
      this.dialog.visible = true
      this.getCustomerList()
    },
    getCustomerList () {
      this.api.risk.white.getWhiteList().then(result => {
        this.customerList = result.data.data
      }).catch(() => {})
    },
    companyChange (val) {
      const data = this._.find(this.customerList, { id: val }) || {}
      const { customerName, customerSocialNo } = data
      this.appForm = this._.assign(this.appForm, { customerName, customerSocialNo })
    },
    dialogAudit (val) {
      this.businessId = val
      this.dialog.audit.visible = true
    },
    submitAudit (formData) {
      return new Promise((resolve, reject) => {
        this.loading.submit = true
        let data = formData || {}
        console.log(formData)
        this.api.risk.black.auditBlack(this.businessId, data.state, data.reason).then(result => {
          if (result.data.success === true) {
            this.loading.submit = false
            this.dialog.audit.visible = false
            this.$message.success('操作成功')
            this.getList()
          } else {
            this.$message.error(result.data.message)
          }
          resolve(result.data.data)
        }).catch(e => {
          reject(e)
        }).finally(() => {
          this.loading.submit = false
        })
      })
    },
    submitForms () {
      this.$refs.appForm.validate().then(() => {
        this.loading.submit = true
        this.api.risk.black.moveInBlack(this.appForm.id, this.appForm.reason).then(result => {
          if (result.data.success === true) {
            this.loading.submit = false
            this.dialog.visible = false
            this.$message.success('操作成功')
            this.getList()
          } else {
            this.$message.error(result.data.message)
          }
        }).catch(e => {
          this.loading.submit = false
        })
      })
    }
  }
}
</script>

<style>

</style>
